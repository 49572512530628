import { Field, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React from "react";
import { useState } from "react";
import { Col, Row, Form as BTSForm, Button } from "react-bootstrap";
import * as yup from 'yup';
import { useSessionStore } from "../../../../Stores/SessionStore";
import BusyIndicator from "../../../Core/BusyIndicator";
import Address from "../../../Core/Customer/Address";
import Communication from "../../../Core/Customer/Communication";
import Details from "../../../Core/Customer/Details";
import NewPasswordFields from "../../../Core/NewPasswordFields";
import PageTitle from "../../../Core/PageTitle";

const UserProfile = observer(function UserProfile(props) {
	const [updatingProfile, setUpdatingProfile] = useState(false);
	const [message, setMessage] = useState(null);
	const store = useSessionStore();

	const validationSchema =
        yup.object({
            Email: yup.string().required("Email is required.").email("Invalid Email Format."),
            CellphoneNumber: yup.string().required("Cellphone Number is required."),
            LanguagePreference: yup.mixed().required("Language Preference is required."),
            EmailPreference: yup.mixed().required("Communication Preference is required."),
			NewPassword: yup.string().nullable().min(8, "New Password must be at least 8 characters."),
			ConfirmNewPassword: yup.string().when('NewPassword', (NewPassword, schema) => {
				return schema.test({
				  test: ConfirmNewPassword => NewPassword === ConfirmNewPassword,
				  message: "Password Confirmation should match New Password"
				})})}
        ).required();

	async function handleFormSubmitClicked(values) {
		setMessage(null);
		setUpdatingProfile(true);

		var result = await store.CustomersService.UpdateProfile({
			CellphoneNumber: values.CellphoneNumber,
			Email: values.Email,
			CommunicationPreference: values.EmailPreference,
			Language: values.LanguagePreference,
			CurrentPassword: values.CurrentPassword,
			NewPassword: values.NewPassword,
			NewPasswordConfirm: values.ConfirmNewPassword
		});

		setUpdatingProfile(false);

		if(result && result.Success) {
			setMessage({ Error: false, Message: "Profile Updated Successfully" });
		} else {
			if(result) {
				setMessage({ Error: true, Message: "Error Updating Profile: " + result.Message });
			} else {
				setMessage({ Error: true, Message: "Error Updating Profile, please contact support." });
			}
		}
	}

	return	<React.Fragment>
				<PageTitle text="Edit Your Profile"></PageTitle>
				{
					store.CustomerDetails && store.CustomerDetails.RadiusDetails &&
					<Formik validationSchema={ validationSchema } initialValues={store.CustomerDetails.Customer} onSubmit={ handleFormSubmitClicked }>
						{({ errors, touched }) => (
							<Form>
								<Row>
									<Col xs={12} md={12} xl={8} className="offset-0 offset-xl-2">
										<Details disabled touched={touched} errors={errors}></Details>
										<Address address={store.CustomerDetails.RadiusDetails.Address}></Address>
										<Communication touched={touched} errors={errors}></Communication>

										<BTSForm.Group as={Row} className="mb-3">
											<BTSForm.Label column sm={4}>ID Number</BTSForm.Label>
											<Col sm={8}>
												<Field readOnly as={BTSForm.Control} name="IDNumber"></Field>
											</Col>
										</BTSForm.Group>

										<BTSForm.Group as={Row} className="mb-3">
											<BTSForm.Label column sm={4}>Current Password</BTSForm.Label>
											<Col sm={8}>
												<Field as={BTSForm.Control} name="CurrentPassword" type="password"></Field>
											</Col>
										</BTSForm.Group>
										<NewPasswordFields touched={touched} errors={errors}></NewPasswordFields>

										<div className="text-center">
											{
												message && message.Error &&
												<span className="text-danger d-block my-5">{ message.Message }</span>
											}

											{
												message && message.Error === false &&
												<span className="text-success d-block my-5">{ message.Message }</span>
											}
										</div>

										<Row className="my-5">
											<Col xs={12} lg={4} className="offset-xs-0 offset-lg-4">
												<Button style={{backgroundImage: "linear-gradient(150deg, #FFEF00 0%, #FF7800 100%)"}} disabled={ updatingProfile } className="w-100" type="submit">Save Changes</Button>
											</Col>
										</Row>
									</Col>
								</Row>
							</Form>
								)}
						</Formik>
					}
					<BusyIndicator show={ store.CustomerDetails === null || store.CustomerDetails.RadiusDetails === null }></BusyIndicator>
			</React.Fragment>
});

export default UserProfile;