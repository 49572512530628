import React, { useEffect, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";
import Loader from "../../../Images/loader.svg"
import { RegistrationSteps } from "../Registration";
import StepContainer from "./StepContainer";

export default function UploadRegistration(props) {
	const [showingError, showError] = useState(false);
	const [saving, setSaving] = useState(true);
	const [errorMessage, setErrorMessage] = useState("");
	const [detailError, setDetailError] = useState(false);
	const store = useSessionStore();

	async function registerCustomer() {
		setSaving(true);
		showError(false);

		if (props.details && props.address && props.files) {
			var customerRegistration = {
				Customer: {
					...props.details,
					Username: props.address.Username,
				},
				IDDocument: props.files.IDDocument,
			}

			var result = await store.CustomersService.RegisterCustomer(customerRegistration);

			if (result && result.Success) {
				props.onContinue(null, RegistrationSteps.UploadRegistration.Step);
			} else {
				if (result) {
					setErrorMessage(result.Message);
					if (result.Message.includes("already exists")) {
						setDetailError(true);
					}
				}

				setSaving(false);
				showError(true);
			}
		}
	}

	useEffect(() => {
		registerCustomer();
	}, [])

	return <StepContainer title="Finalising Registration...">
		<React.Fragment>
			{
				saving &&
				<div>
					<Row className="mb-5 text-center">
						<Col>
							<h4 className="mb-4">One moment. We're just saving your details...</h4>
						</Col>
					</Row>
					<Row className="text-center">
						<Col>
							<img src={Loader}></img>
						</Col>
					</Row>
				</div>
			}

			{
				showingError && (
					<div>
						<Row className="text-center">
							<Col>
								<h6 className="text-danger">An error occurred attempting to register your details{errorMessage && `: ${errorMessage}`}</h6>
							</Col>
						</Row>
						<Row className="mt-5 d-flex">
							{detailError ? (
								<Col>
									<Button className="w-100" onClick={e => props.onContinue(props.address, RegistrationSteps.AddressSelection.Step)}>Start Again</Button>
								</Col>
							) : (
								<Col>
									<Button className="w-100" onClick={e => registerCustomer()}>Retry</Button>
								</Col>
							)}
						</Row>
					</div>
				)
			}
		</React.Fragment>
	</StepContainer>
}