import React from "react";
import { Modal, Button, Row, Col, Stack } from "react-bootstrap";
import { useSessionStore } from "../../../../Stores/SessionStore";

export default function ConfirmAddressModal({ show, onClose }) {
	const store = useSessionStore();

    return(
			<Modal show={ show } size="lg">
				<Modal.Header>
					<h3>
						Confirm Address
					</h3>
				</Modal.Header>
				<Modal.Body>
					<p>Please confirm that you are recharging for the service located at:</p>
					{
						store.CustomerDetails &&
						<Row>
							<Col>
								<div className="mb-3">
									<b>Street</b>
									<div>{ store.CustomerDetails.RadiusDetails.Address.Address }</div> 
								</div>
								<Row className="mb-3">
									<Col>
										<b>City</b>
										<div>{ store.CustomerDetails.RadiusDetails.Address.City }</div> 
									</Col>
									<Col>
										<b>Zip</b>
										<div>{ store.CustomerDetails.RadiusDetails.Address.Zip }</div> 
									</Col>
								</Row>
								<Row>
									<Col>
										<b>Province</b>
										<div>{ store.CustomerDetails.RadiusDetails.Address.State }</div> 
									</Col>
									<Col>
										<b>Country</b>
										<div>{ store.CustomerDetails.RadiusDetails.Address.Country }</div> 
									</Col>
								</Row>
							</Col>
						</Row>
					}
					
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={ () => onClose(true) }> OK </Button>
					<Button onClick={ () => onClose(false) }> Cancel </Button>
				</Modal.Footer>
			</Modal>
	)
}