import React from "react";
import { Col, Form, Row } from "react-bootstrap";

export default function Address(props) {
	if(props.address) {
		return 	<React.Fragment>
					<Form.Group as={Row} className="mb-3">
						<Form.Label column sm={4}>Address</Form.Label>
						<Col sm={8}>
							<input readOnly className="form-control" value={ props.address.Address }></input>
						</Col>
					</Form.Group>
					<Form.Group as={Row} className="mb-3">
						<Form.Label column sm={4}>City</Form.Label>
						<Col sm={8}><input readOnly className="form-control" value={ props.address.City }></input></Col>
					</Form.Group>
					<Form.Group as={Row} className="mb-3">
						<Form.Label column sm={4}>Zip</Form.Label>
						<Col sm={8}><input readOnly className="form-control" value={ props.address.Zip }></input></Col>
					</Form.Group>
					<Form.Group as={Row} className="mb-3">
						<Form.Label column sm={4}>Country</Form.Label>
						<Col sm={8}><input readOnly className="form-control" value={ props.address.Country }></input></Col>
					</Form.Group>
				</React.Fragment>
	} else {
		return <div></div>
	}

}