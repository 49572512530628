import fastInternet from "../Images/fastInternet.png"

import mut from "../Images/mut.jpg"
import dut from "../Images/dut.png"
import wikipedia from "../Images/Wikipedia.jpg"
import unisa from "../Images/unisa.png"

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../CSS/CarouselStyling.css";
import { Carousel } from 'react-responsive-carousel';
import { Button, Col, Container, Row } from "react-bootstrap";
import GreenButton from "./Core/GreenButton"
import LoginModal from "./Portal/LoginModal"
import { useState } from "react"
import Logo from "./Core/Logo"
import ForgotPasswordModal from "./Portal/ForgotPasswordModal"

import "../CSS/style.css"

export default function LandingPage() {
	const [showLoginModal, setShowLoginModal] = useState(false);
	const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);

	return 	<Container fluid>
				<div className="d-flex flex-column p-2 p-md-4">
					<Row className="d-flex align-items-center my-4 mb-5">
						<Col xs={12} md={8} lg={7} className="px-5">
							<Logo onClick={ e => window.location.assign("https://zingfibre.co.za")}></Logo>
						</Col>
						<Col xs={12} md={4} lg={3} className="mt-4 mt-md-0">
							<Button className="w-100 mb-3" style={{backgroundImage: "linear-gradient(130deg, #009845 0%, #FFE200 100%)"}} onClick={ e => setShowLoginModal(true) }>Login</Button>
							<Button className="w-100" style={{backgroundImage: "linear-gradient(150deg, #FFEF00 0%, #FF7800 100%)"}} onClick={ e => window.location.hash = "/Register/Welcome"}>Register</Button>
						</Col>
					</Row>
					<Row>
						<Col className="offset-0 offset-lg-2" lg={ 8 } xs={ 12 } style={{ borderRadius:"32px" }}>
							<Carousel className="rounded-border" autoPlay infiniteLoop showArrows={ false } showThumbs={ false } showIndicators={ false } showStatus={ false }>
								<div>
									<img style={{ width: "60%" }} src={ fastInternet } />
								</div>
							</Carousel>
						</Col>
					</Row>
					<div className="d-flex flex-column mt-5">
						<div><h3 className="text-center pb-3">Access Free Websites Below</h3></div>
						<Row className="d-flex justify-content-center align-items-center">
							<Col md={4} lg={2} className="d-flex justify-content-center align-items-center">
								<GreenButton onClick={ e => window.open("https://www.mut.ac.za/", '_blank').focus() }><img style={{ height: "60px" }} src={ mut }></img></GreenButton>
							</Col>
							<Col md={4} lg={2} className="d-flex justify-content-center align-items-center">
								<GreenButton onClick={ e => window.open("https://www.dut.ac.za/", '_blank').focus() }><img style={{ height: "60px" }} src={ dut }></img></GreenButton>
							</Col>
							<Col md={4} lg={2} className="d-flex justify-content-center align-items-center">
								<GreenButton onClick={ e => window.open("https://www.unisa.ac.za/", '_blank').focus() }><img src={ unisa } style={{ height: "75px" }}></img></GreenButton>
							</Col>
							<Col md={4} lg={2} className="d-flex justify-content-center align-items-center">
								<GreenButton onClick={ e => window.open("https://en.wikipedia.org/wiki/Main_Page", '_blank').focus() }><img src={ wikipedia } style={{ height: "72px" }}></img></GreenButton>
							</Col>
						</Row>
					</div>
					<LoginModal OnForgotPasswordClicked={ e => { setShowLoginModal(false); setShowForgotPasswordModal(true); } } show={ showLoginModal } onClose={ e => setShowLoginModal(false) }></LoginModal>
					<ForgotPasswordModal  show={ showForgotPasswordModal } onClose={ e => setShowForgotPasswordModal(false) } ></ForgotPasswordModal>
				</div>
			</Container>
}