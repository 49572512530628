import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { RegistrationSteps } from "../Registration";
import StepContainer from "./StepContainer";
import Router from "../../../Images/Router.png"

export default function RouterConfirmation(props) {


	return 	<StepContainer title="Do you have a Zing Router installed in your home already?">
				<React.Fragment>
					<Row className="text-center mb-5">
						<Col className="d-flex justify-content-center">
							<img style={{ height: "350px" }} src={ Router }></img>
						</Col>
					</Row>
					<Row>
						<Col xs={12} lg={6} className="text-center mb-3 mb-lg-0">
							<Button className="w-100" onClick={ e => props.onContinue(null, RegistrationSteps.RouterConfirmation.Step) }>Yes, I do</Button>
						</Col>
						<Col xs={12} lg={6} className="text-center">
							<Button className="w-100" onClick={ e => window.open("https://www.zingfibre.co.za/contact-us/#getstarted", '_blank').focus() }>No, I want one</Button>
						</Col>
					</Row>
				</React.Fragment>
			</StepContainer>
}