import React, { useState } from "react";
import { Col, Row, Form as BTSForm, Button, Form } from "react-bootstrap";
import { RegistrationSteps } from "../Registration";
import StepContainer from "./StepContainer";

export default function ConfirmCustomerDetails(props) {
	const [confirmDetails, setConfirmDetails] = useState(false);
	const [showingConfirmationError, showConfirmationError] = useState(false);

	function handleContinueClicked() {
		if(confirmDetails === false) {
			showConfirmationError(true);
		} else {
			showConfirmationError(false);
			props.onContinue(null, RegistrationSteps.CustomerDetailsConfirmation.Step);
		}
	}

	return <StepContainer title="Confirmation of customer details:">
				<React.Fragment>
					<Row>
						<Col>
							{
								props.details &&
								<div>
									<BTSForm.Group as={Row} className="mb-3 mb-sm-0 align-items-center">
										<BTSForm.Label column sm={4}><b>First Name</b></BTSForm.Label>
										<Col sm={8}>
											<span>{props.details.FirstName}</span>
										</Col>
									</BTSForm.Group>
									<BTSForm.Group as={Row} className="mb-3 mb-sm-0 align-items-center">
										<BTSForm.Label column sm={4}><b>Surname</b></BTSForm.Label>
										<Col sm={8}>
											<span>{props.details.Surname}</span>
										</Col>
									</BTSForm.Group>
								</div>
							}
							{
								props.address &&
								<div>
									<BTSForm.Group as={Row} className="mb-3 mb-sm-0 align-items-center">
										<BTSForm.Label column sm={4}><b>Address</b></BTSForm.Label>
										<Col sm={8}>
											<span>{props.address.Address}</span>
										</Col>
									</BTSForm.Group>
									<BTSForm.Group as={Row} className="mb-3 mb-sm-0 align-items-center">
										<BTSForm.Label column sm={4}><b>City</b></BTSForm.Label>
										<Col sm={8}><span>{props.address.City}</span></Col>
									</BTSForm.Group>
									<BTSForm.Group as={Row} className="mb-3 mb-sm-0 align-items-center">
										<BTSForm.Label column sm={4}><b>Zip</b></BTSForm.Label>
										<Col sm={8}><span>{props.address.Zip}</span></Col>
									</BTSForm.Group>
									<BTSForm.Group as={Row} className="mb-3 mb-sm-0 align-items-center">
										<BTSForm.Label column sm={4}><b>Country</b></BTSForm.Label>
										<Col sm={8}><span>{props.address.Country}</span></Col>
									</BTSForm.Group>
								</div>
							}
							{
								props.details &&
								<div>
									<BTSForm.Group as={Row} className="mb-3 mb-sm-0 align-items-center">
										<BTSForm.Label column sm={4}><b>Email</b></BTSForm.Label>
										<Col sm={8}><span>{props.details.Email}</span></Col>
									</BTSForm.Group>
									<BTSForm.Group as={Row} className="mb-3 mb-sm-0 align-items-center">
										<BTSForm.Label column sm={4}><b>Cellphone Number</b></BTSForm.Label>
										<Col sm={8}><span>{ props.details.CellphoneNumber }</span></Col>
									</BTSForm.Group>
									<BTSForm.Group as={Row} className="mb-3 mb-sm-0 align-items-center">
										<BTSForm.Label column xs={12} sm={4} md={4}><b>Login Preference</b></BTSForm.Label>
										<Col xs={12} sm={4} md={2}><span>{props.details.EmailPreference}</span></Col>
									</BTSForm.Group>
									<BTSForm.Group as={Row} className="mb-3 mb-sm-0 align-items-center">
										<BTSForm.Label  column xs={12} sm={4} md={4}><b>Language Preference</b></BTSForm.Label>
										<Col xs={12} sm={4} md={2}><span>{props.details.LanguagePreference === "EN" ? "English" : "Zulu"}</span></Col>
									</BTSForm.Group>
									<BTSForm.Group as={Row} className="mb-3 mb-sm-0 align-items-center">
										<BTSForm.Label column sm={4}><b>{props.details.IDNumberPreference === "ID" ? "ID Number" : "Foreign Passport"}</b></BTSForm.Label>
										<Col sm={8}>
											<span>{props.details.IDNumber}</span>
										</Col>
									</BTSForm.Group>
								</div>
							}

							<Row className="mt-5">
								<Col>
									<Row className="text-center">
										<Col>
											<Form>
												<div className="form-check">
													<input style={{ marginTop: "0.4rem" }} checked={ confirmDetails } onChange={ (e) => setConfirmDetails(e.target.checked)} type="checkbox" className="form-check-input" />
													<label className="form-check-label">I confirm the details I have entered are correct to the best of my knowledge and that the address I am registering for is tenanted or owned by myself or another family member.</label>
												</div>
											</Form>
										</Col>
									</Row>
									<Row className="text-center">
										<Col className="d-flex justify-content-center">
											<h6 className="text-danger">{ (showingConfirmationError ? "Confirmation of details is required" : " ")}</h6>
										</Col>
									</Row>
								</Col>
							</Row>

							<Row className="mt-5">
								<Col  xs={12} lg={6} className="offset-xs-0 offset-lg-3">
									<Button className="w-100" onClick={ handleContinueClicked }>Submit</Button>
								</Col>
							</Row>
						</Col>
					</Row>
				</React.Fragment>
			</StepContainer>
}